<template>
    <div>
      <div v-if="errorMessage">{{ errorMessage }}</div>
      <div v-if="taskCreated">Task Created</div>
      <h2>Subject: {{ emailSubject }}</h2>
        <p>From: {{ emailFrom }}</p>
        <p>Categories: {{ emailCategories }}</p>
    
      <van-collapse v-model="mode">
       <van-collapse-item title="todoist" name="todoist">
        <van-button type="primary" @click="generateTodo" :loading="generatingTask">Generate Task</van-button>
        <van-divider />
        <van-cell-group inset v-if="!taskCreated">
          <van-field
          v-model="task.title"
          name="title"
          label="Task Title"
          label-align="top"
          :rules="[{ required: true, message: 'Task title is required' }]"
        />
        <van-field
        label-align="top"
            v-model="task.description"
            name="description"
            label="Task Description"
            type="textarea"

            :rules="[{ required: true, message: 'Task description is required' }]"
          />
          <van-field
          label-align="top"
              v-model="dueString"
              name="dueString"
              rows="8"
              autosize
              label="Due Date"
              :rules="[{ required: true, message: 'Due Date is required' }]"
            />
            <van-field
            label-align="top"
                v-model="priority"
                name="priority"
                label="Priority"
                :rules="[{ required: true, message: 'Priority is required' }]"
              />
          </van-cell-group>
        <van-button v-if="!taskCreated" type="primary" @click="sendToTodoist" :loading="sendingToTodoist">Send to Todoist</van-button>
       </van-collapse-item>
       <van-collapse-item title="clickup" name="clickup">
        <van-button type="primary" @click="generateClickupTask" :loading="generatingTask" block>Generate Task For Clickup</van-button>
        <van-divider />
        
        <van-cell-group inset v-if="!taskCreated">
          <van-field
          v-model="task.title"
          name="title"
          label="Task Title"
          label-align="top"
          :rules="[{ required: true, message: 'Task title is required' }]"
        />
        <van-field
        label-align="top"
            v-model="task.description"
            name="description"
            label="Task Description"
            type="textarea"

            :rules="[{ required: true, message: 'Task description is required' }]"
          />
          <van-field
          label-align="top"
              v-model="dueString"
              name="dueString"
              rows="8"
              autosize
              label="Due Date"
              :rules="[{ required: true, message: 'Due Date is required' }]"
            />
            <van-field
            label-align="top"
                v-model="priority"
                name="priority"
                label="Priority"
                :rules="[{ required: true, message: 'Priority is required' }]"
              />
          </van-cell-group>
           <select v-model="selectedListId">
      <option disabled value="null">Please select a list</option>
      <option v-for="list in lists" :key="list.id" :value="list.id">
        {{ list.name }}
      </option>
    </select>
    <select v-model="selectedUser">
              <option disabled value="">Please select an assignee</option>
              <option v-for="user in users" :key="user.id" :value="user.id">
                {{ user.username }}
              </option>
            </select>
         <van-button v-if="!taskCreated" type="primary" @click="sendToClickUp" :loading="sendingToClickUp">Send to ClickUp</van-button>

          
      </van-collapse-item>
      </van-collapse>
      
      
       
    </div>
 
</template>

<script setup>
import { Button as VanButton } from 'vant';
import { CellGroup as VanCellGroup } from 'vant';
import { Field as VanField } from 'vant';
import { Divider as VanDivider } from 'vant';
import { Collapse as VanCollapse } from 'vant';
import { CollapseItem as VanCollapseItem } from 'vant';
import OpenAI from "openai";
import { TodoistApi } from "@doist/todoist-api-typescript";
import { ref, onMounted } from 'vue';

const selectedUser = ref(null);
let mode = ref('todoist');
let emailSubject = ref('');
let emailBody = ref('');
let emailFrom = ref('');
let emailCategories = ref('');
let selectedListId = ref(null);
let task = ref({
  title: '',
  description: ''
});
let dueString = ref('Tomorrow At Noon');
let priority = ref(1);
let taskCreated = ref(false);
let generatingTask = ref(false);
let sendingToTodoist = ref(false)
let sendingToClickUp = ref(false);
let errorMessage = ref(null);

const lists = [
  { id: "187793442", name: "BC Calculator" },
  { id: "187791274", name: "Boulder Admin" },
  { id: "187765361", name: "Clearwater" },
  { id: "187793281", name: "CMS" },
  { id: "901001409452", name: "EdgeCRM" },
  { id: "900601601620", name: "Fort Shaw Irrigation District" },
  { id: "187765291", name: "General" },
  { id: "187765469", name: "GID" },
  { id: "381209300", name: "Grizzly Subs" },
  { id: "901100419008", name: "Highnoon" },
  { id: "187793150", name: "Inspire Action" },
  { id: "187792074", name: "Membervault" },
  { id: "187285984", name: "Monarch_web" },
  { id: "187765310", name: "PCCRC" },
  { id: "901101242131", name: "Montana Chamber of Commerce" }
];

const users = [
  {
    id: 12624838,
    username: "Dustin Stewart",
    email: "dustin@edgemarketingdesign.com",
    color: "#304ffe",
    initials: "DS",
  },
  {
    id: 12636078,
    username: "Seth Fischer",
    email: "seth@edgemarketingdesign.com",
    color: "#b388ff",
    initials: "SF",
  },
  {
    id: 12655528,
    username: "Eray Demirel",
    email: "erayera@gmail.com",
    color: "#ff5251",
    initials: "ED",
  },
  {
    id: 12669374,
    username: "Orçun Elektirikçi",
    email: "orcunelektirikci@gmail.com",
    color: "#0197a7",
    initials: "OE",
  },
];




onMounted(async() => {
  // eslint-disable-next-line
  await Office.onReady();
  // eslint-disable-next-line
  const item = Office.context.mailbox.item;
  emailSubject.value = item.subject
 item.body.getAsync("text", async (result) => {
  // eslint-disable-next-line
    if (result.status === Office.AsyncResultStatus.Succeeded) {
      console.log(result.value); // logs the body of the item
      emailBody.value = result.value
    } else {
      console.log(result.error);
    }
  });
  item.categories.getAsync(async (result) => {
    // eslint-disable-next-line
    if (result.status === Office.AsyncResultStatus.Succeeded) {
      console.log(result.value); // logs the body of the item
      emailCategories.value = result.value
    } else {
      console.log(result.error);
    }
  });
  emailFrom.value = item.from
  // eslint-disable-next-line
  console.log(Office.context.mailbox.item);
  // eslint-disable-next-line
  console.log(Office.context.mailbox.item.subject);
  // eslint-disable-next-line
  
  // eslint-disable-next-line
  console.log(Office.context.mailbox.item.from);

 
});


const generateTodo = async () => {
  generatingTask.value = true;
  let openaiResponse;
  const openai = new OpenAI({
    apiKey: "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",
    dangerouslyAllowBrowser: true,
  });

  try {
    openaiResponse = await openai.chat.completions.create({
      model: "gpt-4-0613",
      messages: [
        {
          role: "system",
          content:
            "You are a helpful assistant that is an excellent project manager and organizer. Your job is to create a task that will be added to Todoist by another process.  The user will send you the details of an email, after reading and understanding it, smartly create a task with a title and content property.  Be smart about generating the tasks. Not every detail of the email requires that a task be created. Tasks should be focused on actionable items.",
        },
        {
          role: "system",
          content:
            "Always format your response with the following format: Title: <title> Content: <content>",
        },
        {
          role: "system",
          content:
          "If categories are submitted by the user, add them to the beginning of the task title, like this: Title: <category> - <title>"
        },
        {
          role: "system",
          content:
            "If the user provides a value of `emailFrom: <contact info>`, parse and include this as contact info it at the end of the task description."
        },
        {
          role: "user",
          content: `Subject: ${emailSubject.value}`,
        },
        {
          role: "user",
          content: `Body: ${emailBody.value}`,
        },
        {
          role: "user",
          content: `emailFrom: ${emailFrom.value}`,
        },
        {
          role: "user",
          content: `Categories: ${emailCategories.value}`,
        }
      ],
    })
    const messageContent = openaiResponse.choices[0].message.content.replace(/(^")|("$)/g, "");
    const splitContent = messageContent.split('Content: ');
    task.value = {
      title: splitContent[0].replace('Title: ', '').trim(),
      description: splitContent[1].trim()
    };
    generatingTask.value = false;
  } catch (error) {
    generatingTask.value = false;
    console.error(`${error.message}`);
  }
}
const generateClickupTask = async () => {
  generatingTask.value = true;
  let openaiResponse;
  const openai = new OpenAI({
    apiKey: "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",
    dangerouslyAllowBrowser: true,
  });

  try {
    openaiResponse = await openai.chat.completions.create({
      model: "gpt-4-0613",
      messages: [
        {
          role: "system",
          content:
            "You are a world class project manager and excellent organizer. You manage technology projects and today you are sorting through emails and assigning tasks to our development team. Another process will send your task to the ClickUp API.  You will be sent an email, after reading and understanding it, smartly create a task that will enable the developer to successfully meet the clients needs.  Be smart about generating the tasks. Not every detail of the email requires that a task be created. Tasks should be focused on actionable items.",
        },
        {
          role: "system",
          content:
            "Always format your response with the following format: Title: <task title> Description: <task description>",
        },
        {
          role: "system",
          content:
            "If the user provides a value of `emailFrom: <contact info>`, parse and include this as contact info it at the end of the task description. After you have written out the task description include a line followed by the content of the email."
        },
        {
          role: "user",
          content: `Subject: ${emailSubject.value}`,
        },
        {
          role: "user",
          content: `Body: ${emailBody.value}`,
        },
        {
          role: "user",
          content: `emailFrom: ${emailFrom.value}`,
        },
      ],
    })
    const messageContent = openaiResponse.choices[0].message.content.replace(/(^")|("$)/g, "");
    const splitContent = messageContent.split('Description: ');
    task.value = {
      title: splitContent[0].replace('Title: ', '').trim(),
      description: splitContent[1].trim()
    };
    generatingTask.value = false;
  } catch (error) {
    generatingTask.value = false;
    console.error(`${error.message}`);
  }
}

const sendToTodoist = async() => {
  const todoistToken = process.env.VUE_APP_TODOIST_TOKEN;
  sendingToTodoist.value = true;
  const api = new TodoistApi(todoistToken);
  //format 
  const formattedTask = {
    content: task.value.title,
    description: task.value.description,
    dueString: dueString.value,
    dueLang: "en",
    priority: priority.value
  };
  api
    .addTask(formattedTask)
    .then((todoistResponse) => {
      console.log(todoistResponse);
      taskCreated = true;
      sendingToTodoist.value = false;
    })
    .catch((error) => {
      sendingToTodoist.value = false;
      errorMessage.value = "Failed to create task in ClickUp";
      console.error(error);
    });
}

const sendToClickUp = async () => {
  sendingToClickUp.value = true; // Set loading state to true

  // Prepare the task data
  const clickUpTask = {
    name: task.value.title,
    description: task.value.description,
    assignees: [selectedUser.value], // Assignees must be an array of user IDs
    list_id: selectedListId.value, //
    status: 'Open',
    notify_all: true,
  };

  try {
    // Send the task to ClickUp API
    const response = await fetch('https://api.clickup.com/api/v2/list/' + selectedListId.value + '/task', {
      method: 'POST',
      headers: {
        Authorization: 'pk_12624838_60J9H7H365JX4J6S4UUDJUOX5OVI7NPJ', // Your ClickUp API Key 
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(clickUpTask),
    });

    // Parse the JSON response
    const responseData = await response.json();

    // Check for successful task creation
    if (response.ok) {
      console.log('Task Created Successfully:', responseData);
      taskCreated.value = true; // Assuming you have a ref named taskCreated for this
    } else {
      console.error('Failed to create task:', responseData);
      // Display a failure message here
    }
  } catch (error) {
    // Handle any errors
    console.error('An error occurred:', error);
    // Display a failure message here
  } finally {
    sendingToClickUp.value = false; // Reset the loading state
  }
};


</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
